// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--fJVkM {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n.s-module__wrap--fJVkM .s-module__box--ARFmI {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.s-module__wrap--fJVkM .s-module__box--ARFmI img {\n  margin-bottom: 32px;\n  display: block;\n  width: 300px;\n  height: 300px;\n}\n.s-module__wrap--fJVkM .s-module__box--ARFmI .s-module__tipText--GnfqH {\n  margin-bottom: 32px;\n  color: var(--gray-900);\n  text-align: center;\n  -webkit-text-stroke-width: 1;\n  -webkit-text-stroke-color: var(--black);\n  font-family: Inter;\n  font-size: 44px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 60px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/ChannelNotFound/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACJ;AALA;EAOQ,aAAA;EACA,sBAAA;EACA,mBAAA;AACR;AAVA;EAYY,mBAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;AACZ;AAhBA;EAkBY,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,4BAAA;EACA,uCAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACZ","sourcesContent":[".wrap {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n\n    .box {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n\n        img {\n            margin-bottom: 32px;\n            display: block;\n            width: 300px;\n            height: 300px;\n        }\n        .tipText {\n            margin-bottom: 32px;\n            color: var(--gray-900);\n            text-align: center;\n            -webkit-text-stroke-width: 1;\n            -webkit-text-stroke-color: var(--black);\n            font-family: Inter;\n            font-size: 44px;\n            font-style: normal;\n            font-weight: 500;\n            line-height: 60px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--fJVkM",
	"box": "s-module__box--ARFmI",
	"tipText": "s-module__tipText--GnfqH"
};
export default ___CSS_LOADER_EXPORT___;
