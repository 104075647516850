// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--_RAmB {\n  display: flex;\n  flex-direction: row;\n  padding: 24px 0;\n  align-items: flex-start;\n}\n.s-module__wrap--_RAmB .ant-switch {\n  background: #E5E7EB;\n}\n.s-module__wrap--_RAmB .ant-switch.ant-switch-checked {\n  background: var(--primary-color);\n}\n.s-module__wrap--_RAmB .s-module__weekDayLabel--lemqM {\n  margin-left: 24px;\n  margin-right: 20px;\n  font-size: 16px;\n  font-weight: 500;\n  color: #000;\n  padding-bottom: 4px;\n}\n.s-module__wrap--_RAmB .s-module__weekDayLabel--lemqM::first-letter {\n  text-transform: uppercase;\n}\n.s-module__wrap--_RAmB .s-module__switchWrap--Djodo {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-top: 6px;\n  min-width: 200px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/AppointmentSettingPage/components/AviabilityPage/TimezoneOperationhours/components/WeekItem/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,uBAAA;AACJ;AALA;EAOY,mBAAA;AACZ;AARA;EAUY,gCAAA;AACZ;AAXA;EAcQ,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AAAR;AAnBA;EAsBQ,yBAAA;AAAR;AAtBA;EAyBQ,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AAAR","sourcesContent":[".wrap {\n    display: flex;\n    flex-direction: row;\n    padding: 24px 0;\n    align-items: flex-start;\n    :global {\n        .ant-switch {\n            background: #E5E7EB;\n        }\n        .ant-switch.ant-switch-checked {\n            background: var(--primary-color);\n        }\n    }\n    .weekDayLabel{\n        margin-left: 24px;\n        margin-right: 20px;\n        font-size: 16px;\n        font-weight: 500;\n        color: #000;\n        padding-bottom: 4px;\n    }\n    .weekDayLabel::first-letter{\n        text-transform: uppercase;\n    }\n    .switchWrap{\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        margin-top: 6px;\n        min-width: 200px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--_RAmB",
	"weekDayLabel": "s-module__weekDayLabel--lemqM",
	"switchWrap": "s-module__switchWrap--Djodo"
};
export default ___CSS_LOADER_EXPORT___;
