// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--LZUnt {\n  padding: 16px;\n}\n.s-module__wrap--LZUnt .s-module__title--Erp4S {\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  color: #374151;\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\n.s-module__wrap--LZUnt .ant-form-item {\n  margin-bottom: 0 !important;\n}\n.s-module__wrap--LZUnt .ant-form-item-required::after {\n  display: none !important;\n}\n.s-module__wrap--LZUnt .ant-picker-calendar-mini .ant-picker-cell::before {\n  height: 100%;\n  background-color: #fff;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/AppointmentSettingPage/components/AviabilityPage/DatesOverride/components/AddOverrideModal/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAFA;EAII,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;AACJ;AAVA;EAcM,2BAAA;AADN;AAbA;EAiBM,wBAAA;AADN;AAhBA;EAoBM,YAAA;EACA,sBAAA;AADN","sourcesContent":[".wrap {\n  padding: 16px;\n\n  .title {\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 24px;\n    color: #374151;\n    margin-top: 8px;\n    margin-bottom: 8px;\n  }\n\n  :global {\n    .ant-form-item {\n      margin-bottom: 0 !important;\n    }\n    .ant-form-item-required::after {\n      display: none !important;\n    }\n    .ant-picker-calendar-mini .ant-picker-cell::before {\n      height: 100%;\n      background-color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--LZUnt",
	"title": "s-module__title--Erp4S"
};
export default ___CSS_LOADER_EXPORT___;
