// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--n2kfx {\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n.s-module__wrap--n2kfx h2 img {\n  height: 44px;\n}\n.s-module__wrap--n2kfx .s-module__imgWrap--hffl0 img {\n  width: 300px;\n  margin: 48px;\n}\n.s-module__wrap--n2kfx h1 {\n  color: var(--gray-900);\n  font-size: 38px;\n  font-weight: bold;\n  margin-bottom: 36px;\n}\n.s-module__wrap--n2kfx p {\n  max-width: 900px;\n  width: 90%;\n  color: var(--gray-500);\n  font-size: 18px;\n  line-height: 20px;\n  text-align: center;\n}\n.s-module__wrap--n2kfx .s-module__btn--gpDzK {\n  margin-top: 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/VerificationFailPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACF;AANA;EAOI,YAAA;AAEJ;AATA;EAUI,YAAA;EACA,YAAA;AAEJ;AAbA;EAcI,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAEJ;AAnBA;EAoBI,gBAAA;EACA,UAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AA3BA;EA4BI,gBAAA;AAEJ","sourcesContent":[".wrap{\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  h2 img {\n    height: 44px;\n  }\n  .imgWrap img {\n    width: 300px;\n    margin: 48px;\n  }\n  h1{\n    color: var(--gray-900);\n    font-size: 38px;\n    font-weight: bold;\n    margin-bottom: 36px;\n  }\n  p {\n    max-width: 900px;\n    width: 90%;\n    color: var(--gray-500);\n    font-size: 18px;\n    line-height: 20px;\n    text-align: center;\n  }\n  .btn{\n    margin-top: 36px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--n2kfx",
	"imgWrap": "s-module__imgWrap--hffl0",
	"btn": "s-module__btn--gpDzK"
};
export default ___CSS_LOADER_EXPORT___;
