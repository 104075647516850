// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--aYXcW .s-module__title--lLg1U {\n  margin: 10px 0 6px 0;\n}\n.s-module__wrap--aYXcW .s-module__addIcon--KgdZX {\n  display: inline-block;\n  text-align: center;\n  line-height: 32px;\n  width: 32px;\n  border-radius: 50%;\n  background: #6366F1;\n  color: #fff;\n  font-weight: 500;\n  font-size: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/AppointmentSettingPage/components/BillingPage/components/BillingWrap/s.module.less"],"names":[],"mappings":"AAAA;EAEI,oBAAA;AAAJ;AAFA;EAKI,qBAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;AAAJ","sourcesContent":[".wrap {\n  .title {\n    margin: 10px 0 6px 0;\n  }\n  .addIcon{\n    display: inline-block;\n    text-align: center;\n    line-height: 32px;\n    width: 32px;\n    border-radius: 50%;\n    background: #6366F1;\n    color: #fff;\n    font-weight: 500;\n    font-size: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--aYXcW",
	"title": "s-module__title--lLg1U",
	"addIcon": "s-module__addIcon--KgdZX"
};
export default ___CSS_LOADER_EXPORT___;
